import React from 'react'
import classNames from 'classnames'

const Pulse = ({ className, ...props }) => {
  return (
    <div className={classNames('sk-chase', className)} {...props}>
      <div className="sk-chase-dot"></div>
      <div className="sk-chase-dot"></div>
      <div className="sk-chase-dot"></div>
      <div className="sk-chase-dot"></div>
      <div className="sk-chase-dot"></div>
      <div className="sk-chase-dot"></div>
    </div>
  )
}

export default Pulse
