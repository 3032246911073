const firebaseConfig = {
  apiKey: 'AIzaSyA2AkSDPp-0TqCkM5CfsUC-nqQq8K24Cx4',
  authDomain: 'onlead-54ad3.firebaseapp.com',
  projectId: 'onlead-54ad3',
  storageBucket: 'onlead-54ad3.appspot.com',
  messagingSenderId: '263074933411',
  appId: '1:263074933411:web:f8cd5ac48ed818d0746d16',
  measurementId: 'G-5MF7909J4Q',
}

export { firebaseConfig }
