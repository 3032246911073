import React from 'react'
import classNames from 'classnames'

const ThreeBounce = ({ className, ...props }) => {
  return (
    <div className={classNames('sk-flow', className)} {...props}>
      <div className="sk-flow-dot"></div>
      <div className="sk-flow-dot"></div>
      <div className="sk-flow-dot"></div>
    </div>
  )
}

export default ThreeBounce
