import { SET_ASIDE, SET_BREADCRUMB, SIDEBAR_TOGGLE } from '../actions/dashboard'

const initialState = {
  sidebarShow: 'responsive',
  asideShow: false,
  darkMode: false,
  breadcrumb: [],
}

// dispatch({ type: 'set', sidebarShow: val })

export default (state = initialState, action) => {
  switch (action.type) {
    case SIDEBAR_TOGGLE:
      return { ...state, sidebarShow: action.sidebarShow }
    case SET_ASIDE: {
      return {
        ...state,
        asideShow: action.asideState,
      }
    }
    case SET_BREADCRUMB:
      return {
        ...state,
        breadcrumb: action.breadcrumb,
      }
    case 'set': // has not yet full refactor, remaining set only for breadcrumb, remember to refactor it to the above reducer
      return {
        ...state,
        breadcrumb: action.breadcrumb,
      }
    default:
      return state
  }
}
