import React from 'react'
import classNames from 'classnames'

const FadingCircle = ({ className, ...props }) => {
  return (
    <div className={classNames('sk-circle-fade', className)} {...props}>
      <div className="sk-circle-fade-dot"></div>
      <div className="sk-circle-fade-dot"></div>
      <div className="sk-circle-fade-dot"></div>
      <div className="sk-circle-fade-dot"></div>
      <div className="sk-circle-fade-dot"></div>
      <div className="sk-circle-fade-dot"></div>
      <div className="sk-circle-fade-dot"></div>
      <div className="sk-circle-fade-dot"></div>
      <div className="sk-circle-fade-dot"></div>
      <div className="sk-circle-fade-dot"></div>
      <div className="sk-circle-fade-dot"></div>
      <div className="sk-circle-fade-dot"></div>
    </div>
  )
}

export default FadingCircle
