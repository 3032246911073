import React from 'react'
import Error403 from './views/Errors/Error403'

const Dashboard = React.lazy(() => import('./views/Dashboard/Dashboard'))

const User = React.lazy(() => import('./views/User/User'))
const UserDetails = React.lazy(() =>
  import('./views/User/UserDetails/UserDetails'),
)
const UserHistory = React.lazy(() => import('./views/User/UserHistory'))

const AddAgent = React.lazy(() => import('./views/User/AddAgent/AddAgent'))

const Task = React.lazy(() => import('./views/Task/Task'))
const AddTask = React.lazy(() => import('./views/Task/AddTask/AddTask'))
const TaskDetails = React.lazy(() =>
  import('./views/Task/TaskDetails/TaskDetails'),
)
const TaskHistory = React.lazy(() => import('./views/Task/TaskDetails/History'))

const AssignmentBatch = React.lazy(() =>
  import('./views/AssignmentBatch/AssignmentBatch'),
)
const BatchSubmission = React.lazy(() =>
  import('./views/AssignmentBatch/BatchSubmission/BatchSubmission'),
)
const AddBatch = React.lazy(() =>
  import('./views/AssignmentBatch/AddBatch/AddBatch'),
)
const BatchSettings = React.lazy(() =>
  import('./views/AssignmentBatch/BatchSubmission/Settings'),
)
const AddAssignment = React.lazy(() =>
  import('./views/AssignmentBatch/BatchSubmission/AddAssignment'),
)
const AddCanvassing = React.lazy(() =>
  import('./views/AssignmentBatch/BatchSubmission/AddCanvassing'),
)

const Merchant = React.lazy(() => import('./views/Merchant/Merchant'))
const MerchantDetails = React.lazy(() =>
  import('./views/Merchant/MerchantDetails/MerchantDetails'),
)

const Attendance = React.lazy(() => import('./views/Attendance/Attendance'))

const Settings = React.lazy(() => import('./views/Settings/Settings'))
const Fraud = React.lazy(() => import('./views/Fraud/Fraud'))

const RawAssignment = React.lazy(() =>
  import('./views/RawReport/RawAssignment/RawAssignment'),
)

const RawCanvassing = React.lazy(() =>
  import('./views/RawReport/RawCanvassing/RawCanvassing'),
)
const BatchFileHistory = React.lazy(() =>
  import('./views/AssignmentBatch/BatchHistory/FileHistory/FileHistory'),
)
const NewAutoAllocation = React.lazy(() =>
  import('./views/AssignmentBatch/AutoAllocation/New'),
)
const AddToExistingAutoAllocation = React.lazy(() =>
  import('./views/AssignmentBatch/AutoAllocation/AddToExisting'),
)

const IncompleteAutoAssign = React.lazy(() =>
  import('./views/AssignmentBatch/AutoAllocation/IncompleteAutoAssign'),
)

const FraudFileHistory = React.lazy(() => import('./views/Fraud/FileHistory'))
const RawAssignmentFileHistory = React.lazy(() =>
  import('./views/RawReport/RawAssignment/FileHistory'),
)
const RawCanvassingFileHistory = React.lazy(() =>
  import('./views/RawReport/RawCanvassing/FileHistory'),
)

const UserGroup = React.lazy(() => import('./views/User/UserGroup'))
const UserGroupDetail = React.lazy(() =>
  import('./views/User/UserGroup/Group/Detail'),
)

const RolesAndPermissions = React.lazy(() =>
  import('./views/RolesAndPermissions'),
)

const AddRolesAndPermissions = React.lazy(() =>
  import('./views/RolesAndPermissions/Add'),
)

const EditRolesAndPermissions = React.lazy(() =>
  import('./views/RolesAndPermissions/Edit'),
)

const AddAdmin = React.lazy(() => import('./views/User/Admin/Add'))
const EditAdmin = React.lazy(() => import('./views/User/Admin/Edit'))

const Performance = React.lazy(() =>
  import('./views/Configuration/Performance'),
)

const SummaryReport = React.lazy(() =>
  import('./views/RawReport/SummaryReport'),
)
const SummaryReportFileHistory = React.lazy(() =>
  import('./views/RawReport/SummaryReport/FileHistory'),
)

const BulletinBoard = React.lazy(() =>
  import('./views/Operational/BulletinBoard'),
)
const AddBulletinBoard = React.lazy(() =>
  import('./views/Operational/BulletinBoard/Add'),
)
const EditBulletinBoard = React.lazy(() =>
  import('./views/Operational/BulletinBoard/Edit'),
)

const AgentFulfillmentEdit = React.lazy(() =>
  import('./views/User/UserGroup/AgentFulfillment/Edit'),
)

const RequestAgent = React.lazy(() => import('./views/User/RequestAgent'))
const RequestAgentDetail = React.lazy(() =>
  import('./views/User/RequestAgent/Detail'),
)
const RequestAgentAdd = React.lazy(() =>
  import('./views/User/RequestAgent/Add'),
)
const RequestAgentSettings = React.lazy(() =>
  import('./views/User/RequestAgent/Settings'),
)

const AgentRequest = React.lazy(() => import('./views/User/Agent/AgentRequest'))
const AgentRequestDetail = React.lazy(() =>
  import('./views/User/Agent/AgentRequest/Detail'),
)

// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const routes = [
  {
    path: '/',
    name: 'Dashboard',
    component: Dashboard,
    exact: true,
    access_modifier: {
      id: 'dashboard',
      group: 'dashboard',
      access: 'view',
      disabled: false,
      type: 'index',
    },
  },

  {
    path: '/user',
    name: 'User',
    component: User,
    exact: true,
    access_modifier: {
      id: 'user',
      group: 'user',
      access: 'view',
      disabled: false,
      type: 'index',
    },
  },
  {
    path: '/user/agent/details/:id',
    name: 'Details',
    component: UserDetails,
    exact: true,
    access_modifier: {
      id: 'agent',
      group: 'user',
      access: 'view',
      disabled: false,
      type: 'detail',
    },
  },
  {
    path: '/user/agent/history/:id',
    name: 'User History',
    component: UserHistory,
    exact: true,
    access_modifier: {
      id: 'agent',
      group: 'user',
      access: 'view',
      disabled: false,
      type: 'detail',
    },
  },
  {
    path: '/user/agent/add',
    name: 'Add Agent',
    component: AddAgent,
    exact: true,
    access_modifier: {
      id: 'agent',
      group: 'user',
      access: 'create',
      disabled: false,
      type: 'add',
    },
  },
  {
    path: '/user/agent/edit/:id',
    name: 'Edit Agent',
    component: AddAgent,
    exact: true,
    access_modifier: {
      id: 'agent',
      group: 'user',
      access: 'update',
      disabled: false,
      type: 'edit',
    },
  },
  {
    path: '/user/agent-request',
    name: 'Agent Request',
    component: AgentRequest,
    exact: true,
    access_modifier: {
      id: 'user',
      group: 'user',
      access: 'view',
      disabled: false,
      type: 'index',
    },
  },
  {
    path: '/user/agent-request/detail/:id',
    name: 'Agent Request Detail',
    component: AgentRequestDetail,
    exact: true,
    access_modifier: {
      id: 'user',
      group: 'user',
      access: 'view',
      disabled: false,
      type: 'detail',
    },
  },
  {
    path: '/fraud',
    name: 'Fraud',
    component: Fraud,
    exact: true,
    access_modifier: {
      id: 'fraud',
      group: 'raw_report',
      access: 'view',
      disabled: false,
      type: 'index',
    },
  },
  {
    path: '/project',
    name: 'Project',
    component: Task,
    exact: true,
    access_modifier: {
      id: 'project',
      group: 'operational',
      access: 'view',
      disabled: false,
      type: 'index',
    },
  },
  {
    path: '/project/add',
    name: 'Add Project',
    component: AddTask,
    exact: true,
    noPadding: true,
    access_modifier: {
      id: 'project',
      group: 'operational',
      access: 'create',
      disabled: false,
      type: 'add',
    },
  },
  {
    path: '/project/edit/:id',
    name: 'Edit Project',
    component: AddTask,
    exact: true,
    access_modifier: {
      id: 'project',
      group: 'operational',
      access: 'update',
      disabled: false,
      type: 'edit',
    },
  },
  {
    path: '/project/details/:id',
    name: 'Project Details',
    component: TaskDetails,
    exact: true,
    access_modifier: {
      id: 'project',
      group: 'operational',
      access: 'view',
      disabled: false,
      type: 'detail',
    },
  },
  {
    path: '/batch',
    name: 'Batch',
    component: AssignmentBatch,
    exact: true,
    access_modifier: {
      id: 'batch',
      group: 'operational',
      access: 'view',
      disabled: false,
      type: 'index',
    },
  },
  {
    path: '/batch/submission/:id',
    name: 'Assignment Batch Submission',
    component: BatchSubmission,
    exact: true,
    access_modifier: {
      id: 'batch',
      group: 'operational',
      access: 'view',
      disabled: false,
      type: 'detail',
    },
  },
  {
    path: '/batch/add',
    name: 'Add Batch',
    component: AddBatch,
    exact: true,
    access_modifier: {
      id: 'batch',
      group: 'operational',
      access: 'create',
      disabled: false,
      type: 'add',
    },
  },
  {
    path: '/batch/submission/:id/add-assignment',
    name: 'Add Assignment',
    component: AddAssignment,
    exact: true,
    access_modifier: {
      id: 'batch',
      group: 'operational',
      access: 'create',
      disabled: false,
      type: 'add',
    },
  },
  {
    path: '/batch/submission/:id/edit-assignment',
    name: 'Add Assignment',
    component: AddAssignment,
    exact: true,
    access_modifier: {
      id: 'batch',
      group: 'operational',
      access: 'update',
      disabled: false,
      type: 'edit',
    },
  },
  {
    path: '/batch/submission/:id/add-canvassing',
    name: 'Add Canvassing',
    component: AddCanvassing,
    exact: true,
    access_modifier: {
      id: 'batch',
      group: 'operational',
      access: 'create',
      disabled: false,
      type: 'add',
    },
  },
  {
    path: '/batch/edit/:id',
    name: 'Edit Batch',
    component: AddBatch,
    exact: true,
    access_modifier: {
      id: 'batch',
      group: 'operational',
      access: 'update',
      disabled: false,
      type: 'edit',
    },
  },
  {
    path: '/batch/submission/:id/settings',
    name: 'Batch Settings',
    component: BatchSettings,
    exact: true,
    access_modifier: {
      id: 'batch',
      group: 'operational',
      access: 'update',
      disabled: false,
      type: 'edit',
    },
  },
  {
    path: '/merchant',
    name: 'Merchant',
    component: Merchant,
    exact: true,
    access_modifier: {
      id: 'merchant',
      group: 'operational',
      access: 'view',
      disabled: false,
      type: 'index',
    },
  },
  {
    path: '/merchant/details/:id',
    name: 'Details',
    component: MerchantDetails,
    exact: true,
    access_modifier: {
      id: 'merchant',
      group: 'operational',
      access: 'view',
      disabled: false,
      type: 'detail',
    },
  },

  {
    path: '/attendance',
    name: 'Attendance',
    component: Attendance,
    exact: true,
    access_modifier: {
      id: 'attendance',
      group: 'operational',
      access: 'view',
      disabled: false,
      type: 'index',
    },
  },

  {
    path: '/settings',
    name: 'Settings',
    component: Settings,
    exact: true,
    access_modifier: {
      id: 'settings',
      group: 'configuration',
      access: 'view',
      disabled: false,
      type: 'index',
    },
  },
  {
    path: '/raw-assignment',
    name: 'Raw Assignment',
    component: RawAssignment,
    exact: true,
    access_modifier: {
      id: 'raw_assignment',
      group: 'raw_report',
      access: 'view',
      disabled: false,
      type: 'index',
    },
  },
  {
    path: '/raw-canvassing',
    name: 'Raw Canvassing',
    component: RawCanvassing,
    exact: true,
    access_modifier: {
      id: 'raw_canvassing',
      group: 'raw_report',
      access: 'view',
      disabled: false,
      type: 'index',
    },
  },
  {
    path: '/batch/submission/:id/file-history',
    name: 'Batch File History',
    component: BatchFileHistory,
    exact: true,
    access_modifier: {
      id: 'batch',
      group: 'operational',
      access: 'view',
      disabled: false,
      type: 'detail',
    },
  },
  {
    path: '/batch/auto-allocation/new',
    name: 'New Auto Allocation',
    component: NewAutoAllocation,
    exact: false,
    access_modifier: {
      id: 'batch',
      group: 'operational',
      access: 'create',
      disabled: false,
      type: 'add',
    },
  },
  {
    path: '/batch/auto-allocation/add-to-existing',
    name: 'Add to Existing Auto Allocation',
    component: AddToExistingAutoAllocation,
    exact: false,
    access_modifier: {
      id: 'batch',
      group: 'operational',
      access: 'update',
      disabled: false,
      type: 'edit',
    },
  },
  {
    path: '/batch/incomplete-auto-assign',
    name: 'Incomplete Auto Assign',
    component: IncompleteAutoAssign,
    exact: true,
    access_modifier: {
      id: 'batch',
      group: 'operational',
      access: 'view',
      disabled: false,
      type: 'detail',
    },
  },
  {
    path: '/fraud/file-history',
    name: 'Fraud File History',
    component: FraudFileHistory,
    exact: true,
    access_modifier: {
      id: 'fraud',
      group: 'raw_report',
      access: 'view',
      disabled: false,
      type: 'detail',
    },
  },
  {
    path: '/raw-assignment/file-history',
    name: 'Raw Assignment File History',
    component: RawAssignmentFileHistory,
    exact: true,
    access_modifier: {
      id: 'raw_assignment',
      group: 'raw_report',
      access: 'view',
      disabled: false,
      type: 'detail',
    },
  },
  {
    path: '/raw-canvassing/file-history',
    name: 'Raw Canvassing File History',
    component: RawCanvassingFileHistory,
    exact: true,
    access_modifier: {
      id: 'raw_canvassing',
      group: 'raw_report',
      access: 'view',
      disabled: false,
      type: 'detail',
    },
  },
  {
    path: '/project/details/history/:id',
    name: 'Project Details History',
    component: TaskHistory,
    exact: true,
    access_modifier: {
      id: 'project',
      group: 'operational',
      access: 'view',
      disabled: false,
      type: 'detail',
    },
  },
  {
    path: '/group',
    name: 'User Group',
    component: UserGroup,
    exact: true,
    access_modifier: {
      id: 'group',
      group: 'user',
      access: 'view',
      disabled: false,
      type: 'index',
    },
  },
  {
    path: '/group/detail/:id',
    name: 'User Group Detail',
    component: UserGroupDetail,
    exact: true,
    access_modifier: {
      id: 'group',
      group: 'user',
      access: 'view',
      disabled: false,
      type: 'detail',
    },
  },
  {
    path: '/group/agent-fulfillment/edit',
    name: 'Agent Fulfillment',
    component: AgentFulfillmentEdit,
    exact: true,
    access_modifier: {
      id: 'group',
      group: 'user',
      access: 'update',
      disabled: false,
      type: 'edit',
    },
  },
  {
    path: '/roles-and-permissions',
    name: 'Role & Permission',
    component: RolesAndPermissions,
    exact: true,
    access_modifier: {
      id: 'roles',
      group: 'configuration',
      access: 'view',
      disabled: false,
      type: 'index',
    },
  },
  {
    path: '/roles-and-permissions/add',
    name: 'Add Role & Permission',
    component: AddRolesAndPermissions,
    exact: true,
    access_modifier: {
      id: 'roles',
      group: 'configuration',
      access: 'create',
      disabled: false,
      type: 'add',
    },
  },
  {
    path: '/roles-and-permissions/edit/:id',
    name: 'Edit Role & Permission',
    component: EditRolesAndPermissions,
    exact: true,
    access_modifier: {
      id: 'roles',
      group: 'configuration',
      access: 'update',
      disabled: false,
      type: 'edit',
    },
  },
  {
    path: '/user/admin/add',
    name: 'Add Admin',
    component: AddAdmin,
    exact: true,
    access_modifier: {
      id: 'admin',
      group: 'user',
      access: 'create',
      disabled: false,
      type: 'add',
    },
  },
  {
    path: '/user/admin/edit/:id',
    name: 'Edit Admin',
    component: EditAdmin,
    exact: true,
    access_modifier: {
      id: 'admin',
      group: 'user',
      access: 'update',
      disabled: false,
      type: 'edit',
    },
  },
  {
    path: '/configuration/performance',
    name: 'Performance',
    component: Performance,
    exact: true,
    access_modifier: {
      id: 'performance',
      group: 'performance',
      access: 'view',
      disabled: false,
      type: 'index',
    },
  },
  {
    path: '/raw-report/summary-report',
    name: 'Summary Report',
    component: SummaryReport,
    exact: true,
    access_modifier: {
      id: 'summary_report',
      group: 'raw_report',
      access: 'view',
      disabled: false,
      type: 'index',
    },
  },
  {
    path: '/raw-report/summary-report/file-history',
    name: 'Summary Report File History',
    component: SummaryReportFileHistory,
    exact: true,
    access_modifier: {
      id: 'summary_report',
      group: 'raw_report',
      access: 'view',
      disabled: false,
      type: 'detail',
    },
  },
  {
    path: '/operational/bulletin-board',
    name: 'Bulletin Board',
    component: BulletinBoard,
    exact: true,
    access_modifier: {
      id: 'bulletin_board',
      group: 'operational',
      access: 'view',
      disabled: false,
      type: 'index',
    },
  },
  {
    path: '/operational/bulletin-board/add',
    name: 'Add Bulletin Board',
    component: AddBulletinBoard,
    exact: true,
    access_modifier: {
      id: 'bulletin_board',
      group: 'operational',
      access: 'create',
      disabled: false,
      type: 'add',
    },
  },
  {
    path: '/operational/bulletin-board/edit/:id',
    name: 'Edit Bulletin Board',
    component: EditBulletinBoard,
    exact: true,
    access_modifier: {
      id: 'bulletin_board',
      group: 'operational',
      access: 'update',
      disabled: false,
      type: 'edit',
    },
  },
  {
    path: '/request-agent',
    name: 'Request Agent',
    component: RequestAgent,
    exact: true,
    access_modifier: {
      id: 'agent_request',
      group: 'user',
      access: 'view',
      disabled: false,
      type: 'index',
    },
  },
  {
    path: '/request-agent/settings',
    name: 'Request Agent Settings',
    component: RequestAgentSettings,
    exact: true,
    access_modifier: {
      id: 'agent_request',
      group: 'user',
      access: 'view',
      disabled: false,
      type: 'index',
    },
  },
  {
    path: '/request-agent/detail/:id',
    name: 'Request Agent Detail',
    component: RequestAgentDetail,
    exact: true,
    access_modifier: {
      id: 'agent_request',
      group: 'user',
      access: 'view',
      disabled: false,
      type: 'detail',
    },
  },
  {
    path: '/request-agent/add',
    name: 'Add Request Agent',
    component: RequestAgentAdd,
    exact: true,
    access_modifier: {
      id: 'agent_request',
      group: 'user',
      access: 'create',
      disabled: false,
      type: 'add',
    },
  },
  {
    path: '/error/403',
    name: 'Error 403',
    component: Error403,
    exact: true,
  },
]

export default routes
