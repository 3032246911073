import FadingCircle from './FadingCircle'
import Circle from './Circle'
import ThreeBounce from './ThreeBounce'
import Pulse from './Pulse'

export default {
  fadingcircle: FadingCircle,
  circle: Circle,
  threebounce: ThreeBounce,
  pulse: Pulse,
}
