import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'

const defaultSettings = {
  user: {
    email: '-',
    id: '-',
    name: '-',
    permissionName: '-',
  },
}

const isProduction = process.env.NODE_ENV === 'production'

export const setupSentry = () => {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new BrowserTracing()],
    environment: isProduction ? process.env.NODE_ENV : '',
    tracesSampleRate: 0.02,
    sampleRate: 0.02,
    beforeSend(event) {
      if (!isProduction) return null

      return event
    },
  })
}

export const sentrySetUser = (user = defaultSettings.user) => {
  Sentry.setUser({
    email: user?.email,
    id: user?.id,
    userName: user?.name,
    permissionName: user?.permissionName,
  })
}
