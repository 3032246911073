import React, { useState, useEffect, useLayoutEffect } from 'react'
import 'firebase/analytics'
import Geocode from 'react-geocode'
import routes from '../../routes'
import moment from 'moment-timezone'

import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { AppContext } from '../../context/AppContext'
import { firebaseInit } from '../../services/firebase'
import { capitalizeFirstLetter, downloadUrl, loading } from '../../utils'

import setupAxiosInterceptors from '../../middleware/interceptors'
import ModalLoading from '../../components/Spinner/ModalLoading'

import '../../assets/scss/style.scss'
import 'antd/dist/antd.css'

import { notification } from 'antd'
import {
  AiFillCheckCircle,
  AiFillCloseCircle,
  AiFillInfoCircle,
} from 'react-icons/ai'
import {
  GREEN_50,
  NEUTRAL_90,
  PRIMARY_50,
  RED_50,
} from '../../assets/styles/styles'
import { Flex, Text } from '../../assets/components/components'
import Button from '../../components/Button/Button'
import { useLazyQuery, useMutation } from '@apollo/client'
import {
  EXPORT_LOGFILE,
  GET_LOGFILE_NOTIFICATION,
  GET_UPDATED_USER_DATA,
  UPDATE_RETRY_LOGFILE,
  USER_FORCE_LOGOUT,
} from './queries'
import { useDispatch, useSelector } from 'react-redux'
import { checkAuth, doLogout, updateAuth } from '../../redux/actions/auth'
import { errorHandler } from '../../services/api'
import PrivateRoute from './components/PrivateRoute'
import { sentrySetUser, setupSentry } from '../../services/sentry'

// Containers
const TheLayout = React.lazy(() => import('../../containers/TheLayout'))
const Login = React.lazy(() => import('../Authentication/Login'))
const Workspace = React.lazy(() => import('../Authentication/Workspace'))
const PrivacyPolicy = React.lazy(() => import('../Static/PrivacyPolicy'))
const TnC = React.lazy(() => import('../Static/TnC'))
const ForgotPassword = React.lazy(() => import('../ForgotPassword'))
const ForgotPasswordOTP = React.lazy(() =>
  import('../ForgotPassword/ForgotPasswordOTP'),
)
const ResetPassword = React.lazy(() =>
  import('../ForgotPassword/ResetPassword'),
)

setupSentry()
sentrySetUser()
setupAxiosInterceptors()

const App = () => {
  const dispatch = useDispatch()
  const isAuthenticated = useSelector((state) => state.auth.authenticated)
  const user = useSelector((state) => state.auth.user)
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)

  const isMobile = windowWidth < 768
  const isTablet = windowWidth >= 768 && windowWidth < 992

  const [state, setState] = useState({
    ready: false,
    loading: false,
    toasts: [
      {
        show: false,
        title: '',
        message: '',
        color: 'primary',
      },
    ],
  })

  // file - import / export
  const [fileQueryParams, setFileQueryParams] = useState({
    show_read: false,
    type: '',
    status: '',
  })
  const [exportUrl, setExportUrl] = useState({
    url: '',
    fileName: '',
  })

  const [getExportLogfile] = useMutation(EXPORT_LOGFILE, {
    onCompleted: (data) => {},
    onError: (error) => {
      addtoast({
        title: 'Error',
        message: error.message,
        color: 'danger',
      })
    },
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
  })
  const [getLogfileNotification] = useLazyQuery(GET_LOGFILE_NOTIFICATION, {
    onCompleted: (data) => {
      const result = data?.logFilesNotification

      if (result.length > 0) {
        result.forEach((item, idx) => {
          const { id, name, status, type } = item

          const isFailed = status === 3
          const isFinished = status === 1

          let title = ''
          let message = ''
          let color = ''
          let actionBtnText = ''

          if (isFailed) {
            title = `${capitalizeFirstLetter(type)} Failed`
            message = `Failed at ${type}ing ${name}`
            color = 'danger'
            actionBtnText = 'Retry'
          } else if (isFinished) {
            title = `${capitalizeFirstLetter(type)} Success`
            message = `Success at ${type}ing ${name}`
            color = 'success'
            actionBtnText = 'Export'
          }
          addtoast({
            key: id,
            title,
            message,
            type: 'finish-export',
            color,
            actionBtnText,
            duration: 10,
            onHandle: () => onHandleFinishedExportData(id, status),
            onClose: () => onHandleFinishedExportData(id, 1, 'onClose'),
          })
        })
      }
    },
    onError: (error) => {
      addtoast({
        title: 'Error',
        message: error.message,
        color: 'danger',
      })
    },
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
  })
  const [updateRetryLogfile] = useMutation(UPDATE_RETRY_LOGFILE, {
    onCompleted: (resultData) => {
      const result = resultData?.logFileRetryExport

      if (result) {
        addtoast({
          show: true,
          title: 'Success',
          message: `Success retry export log file, please wait for a moment.`,
          color: 'success',
        })
      }
    },
    onError: () => {
      addtoast({
        show: true,
        title: 'Error',
        message: `Failed to retry export log file`,
        color: 'danger',
      })
    },
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
  })
  const [userForceLogout] = useLazyQuery(USER_FORCE_LOGOUT, {
    onCompleted: (data) => {
      const result = data?.userForceLogout
      if (result) {
        dispatch(doLogout)
      }
    },
    onError: (error) => {
      addtoast({
        title: 'Error',
        message: error.message,
        color: 'danger',
      })
    },
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
  })

  const [getUpdatedUserData] = useLazyQuery(GET_UPDATED_USER_DATA, {
    onCompleted: (data) => {
      const result = data?.me

      if (Object.keys(result).length > 0) {
        updateAuth(result)
      }
    },
    onError: (error) => {
      addtoast({
        title: 'Error',
        message: errorHandler(error),
        color: 'danger',
      })
    },
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
  })

  const resetState = () => {
    setState({
      ...state,
      loading: false,
      toasts: [
        {
          show: false,
          title: '',
          message: '',
          color: 'primary',
          type: '',
        },
      ],
    })
  }

  const update = (key, value) => {
    setState({ ...state, [key]: value })
  }

  const logout = () => {
    resetState()

    doLogout()
  }
  const addtoast = (toast) => {
    const color = toast?.color
      ? toast.color === 'success'
        ? GREEN_50
        : toast.color === 'danger'
        ? RED_50
        : PRIMARY_50
      : NEUTRAL_90

    const icon = toast?.color ? (
      toast.color === 'success' ? (
        <AiFillCheckCircle color={GREEN_50} />
      ) : toast.color === 'danger' ? (
        <AiFillCloseCircle color={RED_50} />
      ) : (
        <AiFillInfoCircle color={PRIMARY_50} />
      )
    ) : null

    const description =
      toast.type === 'finish-export' ? (
        <>
          <Text margin={'0 0 .5rem'}>{toast.message}</Text>
          <Flex justifyContent={'flex-end'}>
            <Button
              color={toast.actionBtnText === 'Retry' ? 'red' : 'primary'}
              padding={'.3rem .5rem'}
              onClick={toast.onHandle}
              loading={toast.actionBtnLoading}
            >
              {toast.actionBtnText}
            </Button>
          </Flex>
        </>
      ) : (
        <Text>{toast.message}</Text>
      )
    const title = (
      <Text fontWeight={'600'} color={color}>
        {toast.title}
      </Text>
    )

    const duration = toast.duration ? toast.duration : 4.5

    notification.open({
      key: toast?.key,
      message: title,
      description,
      icon,
      onClose: toast.onClose,
      duration,
      style: toast?.style,
    })
  }

  const onHandleNotification = () => {
    onHandleExportNotification()
  }

  const onHandleUserForceLogout = async () => {
    const variables = {
      user_id: user?.id,
    }

    await userForceLogout({ variables })
  }

  const onHandleExportNotification = () => {
    const variables = {
      ...fileQueryParams,
      type: 'export',
      status: 'all',
    }

    getLogfileNotification({ variables })
  }

  const onHandleFinishedExportData = async (
    finishedExportId,
    finishedExportStatus,
    type,
  ) => {
    const variables = {
      log_file_id: finishedExportId,
    }
    notification.close(finishedExportId)
    if (finishedExportStatus === 3) {
      // Failed -> Retry again
      updateRetryLogfile({ variables })
    } else if (finishedExportStatus === 1) {
      // Success -> Export File
      const result = await getExportLogfile({ variables })
      const { data, errors } = result
      if (data) {
        const resultData = data?.logfiledownloadfiles
        if (resultData) {
          if (type === 'onClose') return
          if (resultData?.url) {
            setExportUrl({
              url: resultData.url,
              fileName: resultData.name,
            })
          }
        }
      }
    }
  }

  const checkUpdatedUserData = () => {
    getUpdatedUserData()
  }

  const handleWindowSizeChange = () => {
    setWindowWidth(window.innerWidth)
  }

  const getCurrentTimezone = () => {
    return moment.tz.guess()
  }

  useLayoutEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange)
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange)
    }
  }, [])

  useEffect(() => {
    if (exportUrl.url !== '' && exportUrl.fileName !== '') {
      downloadUrl(exportUrl.url, exportUrl.fileName)
    }
  }, [exportUrl])

  useEffect(() => {
    setState({ ...state, ready: true })
    dispatch(checkAuth())
  }, [])

  useEffect(() => {
    let refreshId = ''
    if (isAuthenticated) {
      Geocode.setApiKey(process.env.GOOGLE_API_KEY)
      firebaseInit()
      onHandleNotification()
      onHandleUserForceLogout()

      refreshId = setInterval(checkUpdatedUserData, 86400000) // 24 hour
    }

    return () => clearInterval(refreshId)
  }, [isAuthenticated])

  return (
    <>
      {state.ready ? (
        <>
          <AppContext.Provider
            value={{
              state: state,
              update,
              logout,
              addtoast,
              onHandleNotification,
              getCurrentTimezone,
            }}
          >
            <BrowserRouter>
              <React.Suspense fallback={loading}>
                <Switch>
                  <Route
                    path="/workspace"
                    exact
                    name="Workspace"
                    render={(props) => (
                      <Workspace
                        {...props}
                        isMobile={isMobile}
                        isTablet={isTablet}
                      />
                    )}
                  />
                  <Route
                    path="/login"
                    exact
                    name="Login"
                    render={(props) => (
                      <Login
                        {...props}
                        isMobile={isMobile}
                        isTablet={isTablet}
                      />
                    )}
                  />
                  <Route
                    path="/forgot-password"
                    exact
                    name="Forgot Password"
                    render={(props) => (
                      <ForgotPassword
                        {...props}
                        isMobile={isMobile}
                        isTablet={isTablet}
                      />
                    )}
                  />
                  <Route
                    path="/forgot-password/otp"
                    exact
                    name="Forgot Password OTP"
                    render={(props) => (
                      <ForgotPasswordOTP
                        {...props}
                        isMobile={isMobile}
                        isTablet={isTablet}
                      />
                    )}
                  />
                  <Route
                    path="/forgot-password/reset"
                    exact
                    name="Forgot Password Reset"
                    render={(props) => (
                      <ResetPassword
                        {...props}
                        isMobile={isMobile}
                        isTablet={isTablet}
                      />
                    )}
                  />
                  <Route
                    path="/privacypolicy"
                    exact
                    name="Privacy Policy"
                    render={(props) => <PrivacyPolicy {...props} />}
                  />
                  <Route
                    path="/tnc"
                    exact
                    name="Terms and Conditions"
                    render={(props) => <TnC {...props} />}
                  />
                  {routes.map((g, i) => {
                    return (
                      <PrivateRoute
                        {...g}
                        key={i}
                        path={g.path}
                        name={g.name}
                        exact={g.exact}
                        isAuthenticated={isAuthenticated}
                        component={TheLayout}
                      />
                    )
                  })}
                  {/* <Route render={() => <Redirect to="/" />} /> */}
                </Switch>
              </React.Suspense>
            </BrowserRouter>
          </AppContext.Provider>

          <ModalLoading open={state.loading} />
        </>
      ) : (
        loading
      )}
    </>
  )
}

export default App
