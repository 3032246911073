import figureupload from './figure/upload.png'
import iconaddround from './icons/icon-add-round.png'
import iconbookmark from './icons/icon-bookmark.png'
import iconcalendarblue from './icons/icon-calendar-blue.png'
import iconcall from './icons/icon-call.png'
import iconcamera from './icons/icon-camera.png'
import iconcameras from './icons/icon-cameras.png'
import icondragwhite from './icons/icon-drag-white.png'
import icondrag from './icons/icon-drag.png'
import iconlist from './icons/icon-list.png'
import iconmap from './icons/icon-map.png'
import iconmap2 from './icons/icon-map2.png'
import iconmarker from './icons/icon-marker.png'
import iconnumber from './icons/icon-number.png'
import iconpencilblue from './icons/icon-pencil-blue.png'
import iconpencil from './icons/icon-pencil.png'
import iconplus from './icons/icon-plus.png'
import iconsurvey from './icons/icon-survey.png'
import iconupload from './icons/icon-upload.png'
import iconwarning from './icons/icon-warning.png'
import iconSurvey from './icons/icon_survey.svg'
import logoColored from './logo/logo-onlead-colored.svg'
import error403 from './figure/403.svg'
import workspaceBg from './figure/workspace-bg.png'
import workspaceComponents from './figure/workspace-components.png'
import document from './figure/document.png'
import BulletinBoardDefaultCoverImage from './figure/bulletinBoard/default-cover-image.png'
import iconProfileColored from './icons/icon_profile.svg'
import coloredMail from './figure/colored-mail.svg'
import iconUserBlacklisted from './icons/icon-user-blacklisted.svg'
import iconImageValidation from './icons/icon-image-validation.svg'
import iconWallet from './icons/icon-wallet.svg'
import iconAgents from './icons/icon-agents.svg'
import iconAdmin from './icons/icon-admin.svg'
import iconAddUser from './icons/icon-add-user.svg'
import iconCustomDanger from './icons/custom-danger.svg'
import iconCustomInfo from './icons/custom-info.svg'
import iconCustomSuccess from './icons/custom-success.svg'
import iconCustomWarning from './icons/custom-warning.svg'
import iconUploadFile from './icons/icon-upload-file.svg'
import iconProgress from './icons/custom-progress.svg'
import emptyState from './figure/empty-state.png'
import emptyState1 from './figure/empty-state-1.png'

export default {
  logo: {
    colored: logoColored,
  },
  icons: {
    addround: iconaddround,
    bookmark: iconbookmark,
    calendarblue: iconcalendarblue,
    call: iconcall,
    camera: iconcamera,
    cameras: iconcameras,
    drag: icondrag,
    dragwhite: icondragwhite,
    list: iconlist,
    map: iconmap,
    map2: iconmap2,
    marker: iconmarker,
    number: iconnumber,
    pencilblue: iconpencilblue,
    pencil: iconpencil,
    plus: iconplus,
    survey: iconsurvey,
    upload: iconupload,
    warning: iconwarning,
    clock: iconSurvey,
    profile_colored: iconProfileColored,
    userBlacklisted: iconUserBlacklisted,
    iconImageValidation,
    wallet: iconWallet,
    admin: iconAdmin,
    agents: iconAgents,
    addUser: iconAddUser,
    customWarning: iconCustomWarning,
    customDanger: iconCustomDanger,
    customInfo: iconCustomInfo,
    customSuccess: iconCustomSuccess,
    uploadFile: iconUploadFile,
    customProgress: iconProgress,
  },
  figure: {
    upload: figureupload,
    error403,
    workspaceBg,
    workspaceComponents,
    document,
    bulletinBoard: {
      defaultCoverImage: BulletinBoardDefaultCoverImage,
    },
    notification: {
      coloredMail,
    },
    emptyState,
    emptyState1,
  },
}
