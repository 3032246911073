import { notification } from 'antd'
import { AiFillCloseCircle } from 'react-icons/ai'
import { Text } from '../../assets/components/components'
import { red } from '../../assets/styles/styles'
import { LOAD_LOGIN_DATA, LOAD_WORKSPACE_DATA } from '../../middleware/auth'
import routes from '../../routes'
import constants from '../../utils/constants'

export const LOGIN = 'auth/LOGIN'
export const LOGOUT = 'auth/LOGOUT'
export const SET_AUTHENTICATED = 'auth/SET_AUTHENTICATED'
export const SET_USER_DATA = 'auth/SET_USER_DATA'
export const SET_WORKSPACE_DATA = 'auth/SET_WORKSPACE_DATA'

export const saveWorkspaceAuth =
  (workspaceData, history) => async (dispatch) => {
    localStorage.setItem(
      constants.localStorage.auth.WORKSPACE_AUTH_LOGIN,
      JSON.stringify(workspaceData),
    )
    await dispatch(setWorkspaceData(workspaceData))
    dispatch(redirectPage('/workspace', history))
  }

export const saveAuth = (user, token) => async (dispatch) => {
  const userData = {
    id: user.id,
    name: user.name,
    email: user.email,
    phone_number: user.phone_number,
  }
  const userPermissions = user.role_permissions
  const login_data = {
    user: userData,
    permissions: userPermissions,
    token,
    isAuthenticated: true,
  }

  localStorage.setItem(
    constants.localStorage.auth.AUTH_LOGIN,
    JSON.stringify(login_data),
  )

  await dispatch(setAuthenticated(true))
  await dispatch(
    setUserData(login_data.token, login_data.user, login_data.permissions),
  )

  await dispatch(redirectPageFromLogin())
}

export const updateAuth = async (user) => {
  const data = localStorage.getItem(constants.localStorage.auth.AUTH_LOGIN)
  if (data) {
    const parsed_data = JSON.parse(data)

    const userData = {
      id: user.id,
      name: user.name,
      email: user.email,
      phone_number: user.phone_number,
    }
    const userPermissions = user.role_permissions

    const login_data = {
      ...parsed_data,
      user: userData,
      permissions: userPermissions,
    }

    await localStorage.setItem(
      constants.localStorage.auth.AUTH_LOGIN,
      JSON.stringify(login_data),
    )
  }
}

export const checkAuth = () => async (dispatch) => {
  const loginData = localStorage.getItem(constants.localStorage.auth.AUTH_LOGIN)

  const workspaceData = localStorage.getItem(
    constants.localStorage.auth.WORKSPACE_AUTH_LOGIN,
  )

  //route not in login or workspace
  if (
    window.location.pathname !== '/login' &&
    window.location.pathname !== '/workspace' &&
    window.location.pathname !== '/forgot-password' &&
    window.location.pathname !== '/forgot-password/otp' &&
    window.location.pathname !== '/forgot-password/reset'
  ) {
    // if workspace data empty -> reset login data and redirects to /workspace
    if (!workspaceData) {
      setAuthenticated(false)
      setUserData('', {}, {})
      setWorkspaceData({})
      dispatch(doLogout())
      window.location.href = '/workspace'
      return
    }

    // if login data empty -> redirects to /login
    if (!loginData) {
      setAuthenticated(false)
      setUserData('', {}, {})
      dispatch(doLogout())
      window.location.href = '/login'
      return
    }

    const parsed_data = JSON.parse(loginData) || {}

    // if login data exists but permissions / user / token value doesnt exist
    if (!parsed_data.permissions || !parsed_data.user || !parsed_data.token) {
      notification.open({
        message: (
          <Text fontWeight={'600'} color={red}>
            Error
          </Text>
        ),
        description: 'You are not authorized. Please re-login.',
        icon: <AiFillCloseCircle color={red} />,
      })
      dispatch(doLogout())
    }

    dispatch(loadAuth('login'))
  } else {
    // if route is in login / workspace

    if (window.location.pathname === '/login') {
      if (!loginData && !workspaceData) {
        window.location.href = '/workspace'
      }
    }
  }
}

export const loadAuth = (type) => async (dispatch) => {
  if (type === 'login') {
    dispatch({ type: LOAD_LOGIN_DATA })
  } else {
    dispatch({ type: LOAD_WORKSPACE_DATA })
  }
}

export const setAuthenticated = (isAuthenticated) => async (dispatch) => {
  await dispatch({
    type: SET_AUTHENTICATED,
    isAuthenticated,
  })
}

export const setUserData = (token, user, permissions) => async (dispatch) => {
  await dispatch({
    type: SET_USER_DATA,
    token,
    user,
    permissions,
  })
}

export const setWorkspaceData = (workspace) => async (dispatch) => {
  await dispatch({
    type: SET_WORKSPACE_DATA,
    workspace,
  })
}

export const doLogout = () => {
  localStorage.removeItem(constants.localStorage.auth.AUTH_LOGIN)

  setAuthenticated(false)
  setUserData('', {}, {})
}

export const redirectPageFromLogin = () => async (dispatch, getState) => {
  const { permissions } = getState().auth

  const findFirstVisibleView = permissions?.permissions?.find(
    (item) => item.actions.view,
  )

  if (!findFirstVisibleView) {
    window.location.href = '/'
    return
  }

  let name = ''
  if (
    findFirstVisibleView?.name === 'agent' ||
    findFirstVisibleView?.name === 'admin'
  ) {
    name = 'user'
  } else if (findFirstVisibleView?.name === 'dashboard_analytics') {
    name = 'dashboard'
  } else if (
    findFirstVisibleView?.name === 'fraud_performance' ||
    findFirstVisibleView?.name === 'agent_monitoring_performance'
  ) {
    name = 'performance'
  } else {
    name = findFirstVisibleView?.name
  }

  const findRoute = routes.find(
    (item) =>
      item?.access_modifier?.type === 'index' &&
      item?.access_modifier?.id === name,
  )

  window.location.href = findRoute?.path
}

export const redirectPage = (route, history) => async (dispatch, getState) => {
  const { access_modifier } = route
  const { permissions } = getState().auth

  if (access_modifier) {
    const isRouteUser =
      access_modifier?.id === 'user' &&
      access_modifier?.type === 'index' &&
      access_modifier?.access === 'view'

    const isRoutePerformanceConfig =
      access_modifier?.id === 'performance' &&
      access_modifier?.type === 'index' &&
      access_modifier?.access === 'view'

    // not super admin
    if (!permissions?.default_role) {
      const findPermission = permissions?.permissions?.find((item) => {
        let permissionName = ''
        if ((isRouteUser || isRoutePerformanceConfig) && item?.actions?.view) {
          permissionName = item?.page?.group
        } else {
          permissionName = item?.name
        }

        return permissionName === access_modifier?.id
      })

      //
      const findFraudConfigPermission = permissions?.permissions?.findIndex(
        (item) => item.name === 'fraud_performance' && item?.actions?.view,
      )
      const findAgentMonitoringConfigPermission =
        permissions?.permissions?.findIndex(
          (item) =>
            item.name === 'agent_monitoring_performance' && item?.actions?.view,
        )

      if (
        isRoutePerformanceConfig &&
        findFraudConfigPermission === -1 &&
        findAgentMonitoringConfigPermission === -1
      ) {
        history.push('/error/403')
      }
      //

      // validate /user url
      const findAgentPermission = permissions?.permissions?.findIndex(
        (item) => item.name === 'agent' && item?.actions?.view,
      )
      const findAdminPermission = permissions?.permissions?.findIndex(
        (item) => item.name === 'admin' && item?.actions?.view,
      )

      if (
        isRouteUser &&
        findAgentPermission === -1 &&
        findAdminPermission === -1
      ) {
        history.push('/error/403')
      }
      //

      for (const key in findPermission?.actions) {
        if (key === access_modifier?.access) {
          if (!findPermission?.actions[key]) {
            history.push('/error/403')
          }
        }
      }
    }
  }

  if (route === '/workspace') {
    history.push('/login')
  }
}
