import constants from './constants'

const { REACT_APP_API } = process.env

export const Config = () => {
  const getWorkspaceData = localStorage.getItem(
    constants.localStorage.auth.WORKSPACE_AUTH_LOGIN,
  )

  let API = REACT_APP_API

  if (getWorkspaceData) {
    const parsed_data = JSON.parse(getWorkspaceData)
    API = parsed_data?.api
  }

  return {
    API,
  }
}
