import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Redirect, Route, useHistory } from 'react-router-dom'
import { redirectPage } from '../../../redux/actions/auth'

const PrivateRoute = ({ component: Component, ...rest }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  useEffect(() => {
    dispatch(redirectPage(rest, history))
  }, [])
  return (
    <Route
      {...rest}
      render={(props) =>
        rest.isAuthenticated &&
        (rest.componentWithProps ? Component(props) : <Component {...props} />)
      }
    />
  )
}

export default PrivateRoute
