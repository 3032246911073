import axios from 'axios'
import { Config } from '../../utils/config'

export const errorHandler = (error) =>
  ((error.response || {}).data || {}).message || error.message

// API Assignment

// batch
export const postCreateAssignment = (payload, config) =>
  axios.post(`${Config().API}/admin/assignment`, payload, config)
export const postAddExistingAssignment = (payload, config) =>
  axios.post(`${Config().API}/admin/assignment/add-existing`, payload, config)
export const postCheckHeaderImportFileAssignment = (payload, config) =>
  axios.post(`${Config().API}/admin/assignment/check-header`, payload, config) // check header create/update batch file

// raw
export const postCheckUpdateStatusAssignmentFile = (payload, config) =>
  axios.post(
    `${Config().API}/admin/assignment/check-updated-file`,
    payload,
    config,
  ) // submit imported raw assignment & canvassing file // deprecated
export const postCheckHeaderUpdateStatusAssignmentFile = (payload, config) =>
  axios.post(
    `${Config().API}/admin/assignment/check-updated-header`,
    payload,
    config,
  ) // check header raw assignment & canvassing
export const postUpdateStatusAssignmentFile = (payload, config) =>
  axios.post(`${Config().API}/admin/assignment/update`, payload, config) // submit imported raw assignment & canvassing file
//

//schedule
export const postImportScheduleFile = (payload, config) =>
  axios.post(`${Config().API}/admin/assignment/schedule`, payload, config)
export const postCheckImportedScheduleFile = (payload, config) =>
  axios.post(
    `${Config().API}/admin/assignment/check-schedule-header`,
    payload,
    config,
  )

// API Customer (Merchant)
export const postImportMerchantFile = (payload, config) =>
  axios.post(`${Config().API}/admin/customer`, payload, config)
export const postCheckImportMerchantFile = (payload, config) =>
  axios.post(`${Config().API}/admin/customer/check`, payload, config)

// API User
export const postImportUserFile = (payload, config) =>
  axios.post(`${Config().API}/admin/user`, payload, config)
export const postCheckImportUserFile = (payload, config) =>
  axios.post(`${Config().API}/admin/user/check`, payload, config)
export const postCheckImportUserBlacklistFile = (payload, config) =>
  axios.post(`${Config().API}/admin/user/check/blacklist`, payload, config)
export const postImportUserBlacklistFile = (payload, config) =>
  axios.post(`${Config().API}/admin/user/blacklist`, payload, config)
export const postCheckImportUserTargetFile = (payload, config) =>
  axios.post(`${Config().API}/admin/user/check/target`, payload, config)
export const postImportUserTargetFile = (payload, config) =>
  axios.post(`${Config().API}/admin/user/target`, payload, config)

// API Auto Allocation
export const postCreateTempAssignment = (payload, config) =>
  axios.post(`${Config().API}/admin/tempassignment`, payload, config) // submit new auto allocation - step 1
export const postUpdateTempAssignment = (payload, config) =>
  axios.post(
    `${Config().API}/admin/tempassignment/add-existing`,
    payload,
    config,
  ) // submit add to existing auto allocation - step 1
export const postCheckTempAssignmentLeads = (payload, config) =>
  axios.post(
    `${Config().API}/admin/tempassignment/check-leads`,
    payload,
    config,
  ) //
export const postCheckTempAssignmentAgents = (payload, config) =>
  axios.post(
    `${Config().API}/admin/tempassignment/check-agents`,
    payload,
    config,
  ) //
export const postSubmitTempAssignment = (payload, config) =>
  axios.post(
    `${Config().API}/admin/tempassignment/import-files`,
    payload,
    config,
  ) //
export const postUpdateLeadsFile = (payload, config) =>
  axios.post(
    `${Config().API}/admin/tempassignment/replace-leads`,
    payload,
    config,
  ) //
export const postUpdateAgentsFile = (payload, config) =>
  axios.post(
    `${Config().API}/admin/tempassignment/replace-agents`,
    payload,
    config,
  ) //

// API Group
export const postCheckImportGroupFile = (payload, config) =>
  axios.post(`${Config().API}/admin/group/check`, payload, config) //
export const postImportGroupFile = (payload, config) =>
  axios.post(`${Config().API}/admin/group`, payload, config) //

// API Bulletin Board
export const postCreateBulletinBoard = (payload, config) =>
  axios.post(`${Config().API}/admin/bulletinboard`, payload, config)
export const putUpdateBulletinBoard = (id, payload, config) =>
  axios.put(`${Config().API}/admin/bulletinboard/${id}`, payload, config)

// Request Agent
export const postCreateRequestAgent = (payload, config) =>
  axios.post(`${Config().API}/requestagent`, payload, config)
export const postCreateSingleRequestAgent = (payload, config) =>
  axios.post(`${Config().API}/requestagent/single`, payload, config)
