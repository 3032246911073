export const AUTO_ALLOCATION_NEW_STEP_1 = '/batch/auto-allocation/new/step-1'
export const AUTO_ALLOCATION_NEW_STEP_2 = '/batch/auto-allocation/new/step-2'
export const AUTO_ALLOCATION_NEW_STEP_3 = '/batch/auto-allocation/new/step-3'

export const AUTO_ALLOCATION_EXISTING_STEP_1 =
  '/batch/auto-allocation/add-to-existing/step-1'
export const AUTO_ALLOCATION_EXISTING_STEP_2 =
  '/batch/auto-allocation/add-to-existing/step-2'
export const AUTO_ALLOCATION_EXISTING_STEP_3 =
  '/batch/auto-allocation/add-to-existing/step-3'

export default {
  autoAllocation: {
    AUTO_ALLOCATION_NEW_STEP_1,
    AUTO_ALLOCATION_NEW_STEP_2,
    AUTO_ALLOCATION_NEW_STEP_3,
    AUTO_ALLOCATION_EXISTING_STEP_1,
    AUTO_ALLOCATION_EXISTING_STEP_2,
    AUTO_ALLOCATION_EXISTING_STEP_3,
  },
}
