import { Col, Row } from 'antd'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { Flex, Text, Wrapper } from '../../assets/components/components'
import images from '../../assets/images'
import { blue } from '../../assets/styles/styles'

const Error403 = () => {
  const history = useHistory()
  return (
    <Wrapper margin={'3rem 0 0 0'}>
      <Row>
        <Col xs={24} sm={24}>
          <Flex justifyContent={'center'}>
            <img src={images.figure.error403} width={'50%'} height={'100%'} />
          </Flex>
        </Col>
      </Row>
      <Row>
        <Col xs={24} sm={24}>
          <Text
            fontSize={'2rem'}
            fontWeight={'800'}
            textAlign={'center'}
            margin={'2.5rem auto 0 auto'}
          >
            Access Denied
          </Text>
        </Col>
      </Row>
      <Row>
        <Col xs={24} sm={24}>
          <Text
            maxWidth={'350px'}
            fontSize={'1rem'}
            textAlign={'center'}
            margin={'0 auto'}
          >
            Oops, you don’t have permission to this page. Contact your admin or
            go back to{' '}
            <Text
              display={'inline'}
              color={blue}
              cursor={'pointer'}
              onClick={() => history.push('/')}
            >
              Dashboard
            </Text>
            .
          </Text>
        </Col>
      </Row>
    </Wrapper>
  )
}

export default Error403
