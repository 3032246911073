import moment from 'moment'
import { saveAs } from 'file-saver'

export function deepCopy(arr) {
  return JSON.parse(JSON.stringify(arr))
}

export function handleGQLError(err) {
  err =
    err.graphQLErrors && err.graphQLErrors.length > 0
      ? err.graphQLErrors[0].message
      : 'Server error'
  return err
}

export function formatMoney(value) {
  return value
    .toString()
    .replace(/\D/g, '')
    .replace(/\B(?=(\d{3})+(?!\d))/g, '.')
}

// capitalize first letter in each words
export function capitalizeFirstLetter(string) {
  if (string.indexOf(' ') >= 0) {
    let splitStr = string.toLowerCase().split(' ')
    for (let i = 0; i < splitStr.length; i++) {
      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1)
    }

    return splitStr.join(' ')
  }
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export function trimPhoneNumber(number) {
  if (number[0] === '0') {
    number = number.substring(1, number.length)
  }
  if (
    number[0] === '+' &&
    number[1] === '6' &&
    (number[2] === '2' || number[2] === '3')
  ) {
    number = number.substring(3, number.length)
  }
  if (number[0] === '6' && (number[1] === '2' || number[1] === '3')) {
    number = number.substring(2, number.length)
  }

  return number
}

export function generatePhoneNumber(number, countryCode) {
  const trimmedPhoneNumber = trimPhoneNumber(number)
  number = countryCode + trimmedPhoneNumber

  return number
}

export function getParameterByName(name, url = window.location.href) {
  name = name.replace(/[\[\]]/g, '\\$&')
  var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url)
  if (!results) return null
  if (!results[2]) return ''
  return decodeURIComponent(results[2].replace(/\+/g, ' '))
}

export function mapInputKey(type) {
  switch (type) {
    case 'text':
      return 'value'
    case 'checkbox':
      return 'checked'
    case 'radio':
      return 'value'
    case 'file':
      return 'files'
    default:
      return 'value'
  }
}

export function convertFileToBase64(file) {
  return new Promise((resolve, reject) => {
    let reader = new FileReader()
    reader.readAsDataURL(file)

    reader.onload = () => {
      let result = reader.result
      result = result.split(',')
      resolve(result[result.length - 1])
    }

    reader.onerror = reject
  })
}

export function encode(input) {
  var keyStr =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/='
  var output = ''
  var chr1, chr2, chr3, enc1, enc2, enc3, enc4
  var i = 0

  while (i < input.length) {
    chr1 = input[i++]
    chr2 = i < input.length ? input[i++] : Number.NaN // Not sure if the index
    chr3 = i < input.length ? input[i++] : Number.NaN // checks are needed here

    enc1 = chr1 >> 2
    enc2 = ((chr1 & 3) << 4) | (chr2 >> 4)
    enc3 = ((chr2 & 15) << 2) | (chr3 >> 6)
    enc4 = chr3 & 63

    if (isNaN(chr2)) {
      enc3 = enc4 = 64
    } else if (isNaN(chr3)) {
      enc4 = 64
    }
    output +=
      keyStr.charAt(enc1) +
      keyStr.charAt(enc2) +
      keyStr.charAt(enc3) +
      keyStr.charAt(enc4)
  }
  return output
}

export const exportData = (
  rows,
  delimiter,
  filename,
  fileExtension,
  isDataObject,
  header,
) => {
  let extension = ''
  if (fileExtension === 'csv') {
    extension = 'data:text/csv'
  }

  const headerContent = isDataObject
    ? `${Object.keys(rows[0]).join(delimiter)}\r\n`
    : header
  const rowContent = isDataObject
    ? rows.map((row) => Object.values(row).join(delimiter)).join('\r\n')
    : rows.join('\r\n')

  const content = `${headerContent}${rowContent}`
  var blobResult = new Blob([content], { type: 'text/csv' }) //new way
  var url = URL.createObjectURL(blobResult)

  const link = document.createElement('a')
  link.setAttribute('href', url)
  link.setAttribute('download', filename)
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

export const denormalizeString = (string, delimiter = '_') => {
  return string.split(delimiter).join(' ')
}

// format: DD/MM/YYYY, exp: 05/01/2022
export const dateTimeFormatter = (date) => {
  return moment(date).format('DD/MM/YYYY')
}

// format: DD/MM/YYYY HH:mm , exp: 05/01/2022 17:30
export const dateTimeFormatter1 = (date) => {
  return moment(date).format('DD/MM/YYYY HH:mm')
}

// format: dddd, DD MM YYYY HH:mm, exp: Sunday, 05 January 2022 05:20
export const dateTimeFormatter2 = (date) => {
  return moment(date).format('dddd, DD MMMM YYYY HH:mm')
}

// format: dddd, DD MM YYYY exp: Sunday, 05 January 2022
export const dateTimeFormatter3 = (date) => {
  return moment(date).format('dddd, DD MMMM YYYY')
}

// format: HH:mm exp: 17:50
export const dateTimeFormatter4 = (date) => {
  return moment(date).format('HH:mm')
}

// format: DD MM YYYY exp:  05 January 2022
export const dateTimeFormatter5 = (date) => {
  return moment(date).format('DD MMMM YYYY')
}

// format: DD MM YYYY exp:  05 January 2022, 16:50
export const dateTimeFormatter6 = (date) => {
  return moment(date).format('DD MMMM YYYY HH:mm')
}

// format: ddd, DD MM YYYY HH:mm, exp: Sun, 05 January 2022 05:20
export const dateTimeFormatter7 = (date) => {
  return moment(date).format('ddd, DD MMMM YYYY HH:mm')
}

export const downloadUrl = (url, fileName, context) => {
  fetch(url)
    .then((res) => res.blob())
    .then((blob) => {
      const newBlob = new Blob([blob], { type: 'text/csv;charset=utf-8' })
      saveAs(newBlob, fileName)
    })
    .catch((error) => {
      context.addtoast({
        show: true,
        title: 'Error',
        message: 'Cannot download file, please try again.',
        color: 'danger',
      })
    })
}

export const getDayDiff = (fromDate, toDate) => {
  const from = moment(fromDate)
  const to = moment(toDate)

  return to.diff(from, 'days')
}

export const initialFormatter = (name) => {
  let initial = ''
  if (name) {
    name = name.split(' ')
    if (name.length > 1)
      initial =
        name[0].charAt(0).toUpperCase() + name[1].charAt(0).toUpperCase()
    else initial = name[0].charAt(0).toUpperCase()
  }
  return initial
}

export const transformUrlToFileObj = async (fileObject) => {
  const blob = await fetch(fileObject?.url).then((res) => res.blob())

  const fileObjectRes = new File([blob], fileObject?.filename, {
    type: fileObject?.type,
  })

  return fileObjectRes
}
