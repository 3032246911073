import { css } from '@emotion/core'
import styled from '@emotion/styled'
import {
  border,
  green,
  NEUTRAL_30,
  NEUTRAL_100,
  redbg,
  redsoft,
  white,
  PRIMARY_60,
  GREEN_60,
  NEUTRAL_10,
} from '../styles/styles'

const btn = css`
  display: inline-block;
  margin-bottom: 0;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  outline: none;
  box-shadow: none;
  font-weight: 600;
  font-size: 13px;
  transition: 0.1s all;
  &:focus,
  &:active:focus,
  &.active:focus {
    outline: none;
    box-shadow: none;
  }
`

const btncolor = {
  primary: css`
    background-color: ${PRIMARY_60};
    border: 1px solid ${PRIMARY_60};
    color: ${white};
    &:hover {
      opacity: 0.9;
    }
  `,
  'primary-ghost': css`
    background-color: transparent;
    color: ${PRIMARY_60};
    border: 1px solid ${PRIMARY_60};
    &:hover {
      background-color: ${PRIMARY_60};
      color: ${white} !important;
    }
  `,
  'primary-link': css`
    background-color: transparent;
    color: ${PRIMARY_60};
    &:hover {
      opacity: 0.9;
    }
  `,
  red: css`
    background-color: ${redsoft};
    color: ${white};
    &:hover {
      opacity: 0.9;
    }
  `,
  redsoft: css`
    background-color: ${redbg};
    color: ${white};
    &:hover {
      opacity: 0.9;
    }
  `,
  'red-ghost': css`
    background-color: transparent;
    color: ${redsoft};
    border: 1px solid ${redsoft};
    &:hover {
      background-color: ${redsoft};
      color: ${white};
    }
  `,
  'red-link': css`
    background-color: transparent;
    color: ${redsoft};
    &:hover {
      opacity: 0.9;
    }
  `,
  green: css`
    background-color: ${green};
    color: ${white};
    &:hover {
      opacity: 0.9;
    }
  `,
  'green-ghost': css`
    background-color: transparent;
    color: ${GREEN_60};
    border: 1px solid ${GREEN_60};
    &:hover {
      background-color: ${GREEN_60};
      color: ${white};
    }
  `,
}

const Relative = styled.div`
  position: relative;
  margin: ${(props) => props.margin};
  padding: ${(props) => props.padding};
  background-color: ${(props) => props.backgroundColor};
  width: ${(props) => props.width};
  border-radius: ${(props) => props.borderRadius};
  cursor: ${(props) => props.cursor};
  border: ${(props) => props.border};
`

const Fixed = styled.div`
  position: fixed;
  margin: ${(props) => props.margin};
  padding: ${(props) => props.padding};
  cursor: ${(props) => props.cursor};
`

const Absolute = styled.div`
  position: absolute;
  top: ${(props) => props.top};
  left: ${(props) => props.left};
  right: ${(props) => props.right};
  bottom: ${(props) => props.bottom};
  cursor: ${(props) => props.cursor};
  transform: ${(props) => props.transform};
  z-index: ${(props) => props.zIndex};
  border-radius: ${(props) => props.borderRadius};
  display: ${(props) => props.display};
  margin: ${(props) => props.margin};
  width: ${(props) => props.width};
  height: ${(props) => props.height};
`

const FormGroup = styled.div`
  position: relative;
  margin: ${(props) => props.margin || '0 0 30px'};
  width: 100%;
`

const Flex = styled.div`
  flex: ${(props) => props.flex};
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  display: flex;
  justify-content: ${(props) =>
    props.justifyContent ? props.justifyContent : 'flex-start'};
  align-items: ${(props) => (props.alignItems ? props.alignItems : 'center')};
  margin: ${(props) => props.margin};
  padding: ${(props) => props.padding};
  background-color: ${(props) => props.backgroundColor};
  flex-direction: ${(props) => (props.column ? 'column' : 'row')};
  overflow: ${(props) => props.overflow};
  white-space: ${(props) => props.whiteSpace};
  opacity: ${(props) => props.opacity};
  cursor: ${(props) => props.cursor};
  border: ${(props) => props.border};
  flex-wrap: ${(props) => props.flexWrap};
  border-top: ${(props) => props.borderTop};
  border-left: ${(props) => props.borderLeft};
  border-bottom: ${(props) => props.borderBottom};
  border-right: ${(props) => props.borderRight};
  border-radius: ${(props) => props.borderRadius};
`

const FlexCell = styled.div`
  flex: ${(props) => props.flex};
  margin: ${(props) => props.margin};
  padding: ${(props) => props.padding};
  text-align: ${(props) => props.textAlign};
  width: ${(props) => props.width};
  border: ${(props) => props.border};
  background-color: ${(props) => props.backgroundColor};
`

const FlexCellShadow = styled.div`
  height: ${(props) => props.height};
  width: ${(props) => props.width};
  flex: ${(props) => props.flex};
  margin: ${(props) => props.margin};
  padding: ${(props) => props.padding};
  text-align: ${(props) => props.textAlign};
  -webkit-box-shadow: 0 4px 6px -6px black;
  -moz-box-shadow: 0 4px 6px -6px black;
  box-shadow: 0 4px 6px -6px black;
`

const InlineBlock = styled.div`
  display: inline-block;
  width: ${(props) => props.width};
  margin: ${(props) => props.margin};
  padding: ${(props) => props.padding};
  background-color: ${(props) => props.backgroundColor};
  border-radius: ${(props) => props.borderRadius};
  cursor: ${(props) => props.cursor};
`

const WhiteSpace = styled.div`
  white-space: ${(props) => props.type};
  padding: ${(props) => props.padding};
  margin: ${(props) => props.margin};
`

const Text = styled.div`
  font-size: ${(props) => props.fontSize};
  margin: ${(props) => props.margin};
  font-weight: ${(props) => props.fontWeight};
  text-align: ${(props) => props.textAlign};
  color: ${(props) => props.color || NEUTRAL_100};
  font-style: ${(props) => props.fontStyle};
  text-transform: ${(props) => props.textTransform};
  cursor: ${(props) => props.cursor};
  text-decoration: ${(props) => props.textDecoration};
  display: ${(props) => (props.display ? props.display : 'block')};
  font-family: ${(props) => props.fontFamily};
  padding: ${(props) => props.padding};
  opacity: ${(props) => props.opacity};
  white-space: ${(props) => props.whiteSpace};
  word-break: ${(props) => props.wordBreak};
  max-width: ${(props) => props.maxWidth};
  line-height: ${(props) => props.lineHeight};
  width: ${(props) => props.width};
`

const Link = styled.a`
  font-size: ${(props) => props.fontSize};
  margin: ${(props) => props.margin};
  font-weight: ${(props) => props.fontWeight};
  text-align: ${(props) => props.textAlign};
  color: ${(props) => props.color} !important;
  font-style: ${(props) => props.fontStyle};
  text-transform: ${(props) => props.textTransform};
  cursor: ${(props) => props.cursor};
  text-decoration: ${(props) => props.textDecoration};
  display: ${(props) => (props.display ? props.display : 'block')};
  font-family: ${(props) => props.fontFamily};
  padding: ${(props) => props.padding};
  opacity: ${(props) => props.opacity};
  white-space: ${(props) => props.whiteSpace};
  word-break: ${(props) => props.wordBreak};
  max-width: ${(props) => props.maxWidth};
  line-height: ${(props) => props.lineHeight};
  width: ${(props) => props.width};
`

const Image = styled.img`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  margin: ${(props) => props.margin};
  object-fit: ${(props) => props.objectFit};
  cursor: ${(props) => props.cursor};
  position: ${(props) => props.position};
  left: ${(props) => props.left};
  top: ${(props) => props.top};
  border-radius: ${(props) => props.borderRadius};
  cursor: ${(props) => props.pointer};
  display: ${(props) => props.display};
`

const Separator = styled.div`
  border-bottom: ${(props) => props.borderWidth || '1px'}
    ${(props) => props.borderStyle || 'solid'}
    ${(props) => props.borderColor || border};
  margin: ${(props) => props.margin};
`

const Divider = styled.div`
  display: inline-block;
  width: 100%;
  background-color: ${(props) =>
    props?.backgroundColor ? props?.backgroundColor : NEUTRAL_30};
  margin: ${(props) => props.margin || '0'};
  height: ${(props) => props.height || '1px'};
  border-radius: ${(props) => props.borderRadius};
`

const Icon = styled.i`
  margin: ${(props) => props.margin};
  cursor: ${(props) => props.cursor};
  color: ${(props) => props.color};
  opacity: ${(props) => props.opacity};
`

const BoxShadow = styled.div`
  box-shadow: 0 1px 20px 0 rgba(155, 155, 155, 0.2);
  padding: ${(props) => props.padding || '20px 25px'};
  border-radius: ${(props) => props.borderRadius};
  background-color: ${(props) => props.backgroundColor};
  margin: ${(props) => props.margin};
`

const Card = styled.div`
  box-shadow: ${(props) =>
    props.boxShadow
      ? props.boxShadow
      : '0 1px 20px 0 rgba(155, 155, 155, 0.2)'};
  padding: ${(props) => props.padding || '0'};
  border-radius: ${(props) => props.borderRadius || '7px'};
  background-color: ${(props) => props.backgroundColor || white};
  margin: ${(props) => props.margin || '0 0 30px'};
  border: ${(props) => props.border};
  width: ${(props) => props.width};
  height: ${(props) => props.height};
`

const ButtonStyled = styled.button`
  ${btn};
  border: ${(props) => props.border};
  text-transform: ${(props) => (props.uppercase ? 'uppercase' : 'inherit')};
  border-radius: ${(props) => (props.rounded ? '50%' : props.radius || '5px')};
  padding: ${(props) => props.padding || '10px 15px'};
  margin: ${(props) => props.margin || '0'};
  width: ${(props) => (props.block ? '100%' : props.width)};
  border-color: ${(props) => (props.borderColor ? props.borderColor : white)};
  font-size: ${(props) => props.fontSize};
  background-color: ${(props) =>
    props.disabled ? '#bcbcbc' : props.backgroundColor};
  color: ${(props) => props.textColor};
  border-color: ${(props) =>
    props.disabled ? '#bcbcbc' : props.borderColor ? props.borderColor : white};
  ${(props) => btncolor[props.color]};
`

const DropdownButtonStyled = styled.button`
  ${btn};
  border: ${(props) => props.border};
  text-transform: ${(props) => (props.uppercase ? 'uppercase' : 'inherit')};
  border-radius: ${(props) => (props.rounded ? '50%' : props.radius || '5px')};
  padding: ${(props) => props.padding || '10px 15px'};
  margin: ${(props) => props.margin || '0'};
  width: ${(props) => (props.block ? '100%' : props.width)};
  border-color: ${(props) => (props.borderColor ? props.borderColor : white)};
  font-size: ${(props) => props.fontSize};
  background-color: ${(props) =>
    props.disabled ? '#bcbcbc' : props.backgroundColor};
  color: ${(props) => props.textColor};
  border-color: ${(props) =>
    props.disabled ? '#bcbcbc' : props.borderColor ? props.borderColor : white};
  ${(props) => btncolor[props.color]};
`

const AlertStyled = styled.div`
  background-color: ${(props) => props.backgroundColor};
  padding: ${(props) => props.padding || '15px'};
  margin: ${(props) => props.margin || '0 0 30px'};
  border-radius: ${(props) => props.borderRadius || '7px'};
  display: ${(props) => props.display || 'flex'};
  justify-content: ${(props) =>
    props.justifyContent ? props.justifyContent : 'flex-start'};
  align-items: ${(props) => (props.alignItems ? props.alignItems : 'center')};
  border: ${(props) => props.border};
  height: ${(props) => props.height};
  width: ${(props) => props.width};
`

const IconWrapper = styled.div`
  background-color: ${(props) => props.backgroundColor};
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  border-radius: ${(props) => props.borderRadius};
  display: flex;
  justify-content: ${(props) =>
    props.justifyContent ? props.justifyContent : 'center'};
  align-items: ${(props) => (props.alignItems ? props.alignItems : 'center')};
  margin: ${(props) => props.margin};
  padding: ${(props) => props.padding};
  cursor: ${(props) => props.cursor};
`

const Wrapper = styled.div`
  margin: ${(props) => props.margin};
  padding: ${(props) => props.padding};
  background-color: ${(props) => props.backgroundColor};
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  border-radius: ${(props) => props.borderRadius};
  cursor: ${(props) => props.cursor};
  border: ${(props) => props.border};
  border-left: ${(props) => props.borderLeft};
  max-width: ${(props) => props.maxWidth};
  overflow-y: ${(props) => props.overflowY};
  overflow-x: ${(props) => props.overflowX};
  display: ${(props) => props.display};
  -webkit-box-shadow: ${(props) => props.boxShadow};
  -moz-box-shadow: ${(props) => props.boxShadow};
  box-shadow: ${(props) => props.boxShadow};
  border-bottom: ${(props) => props.borderBottom};
  border-top: ${(props) => props.borderTop};
  text-align: ${(props) => props.textAlign};
  max-height: ${(props) => props.maxHeight};
`

const BackgroundImage = styled.div`
  background-image: url('${(props) => props.src}');
  background-position: center center;
  background-size: cover;
  width: ${(props) => (props.width ? props.width : '100%')};
  height: ${(props) => (props.height ? props.height : '100%')};
  margin: ${(props) => props.margin};
  padding: ${(props) => props.padding};
  position: ${(props) => props.position};
`

export {
  btn,
  Relative,
  Absolute,
  FormGroup,
  Flex,
  FlexCell,
  FlexCellShadow,
  InlineBlock,
  WhiteSpace,
  Text,
  Image,
  Separator,
  Icon,
  Fixed,
  Divider,
  BoxShadow,
  Card,
  ButtonStyled,
  AlertStyled,
  DropdownButtonStyled,
  IconWrapper,
  Wrapper,
  BackgroundImage,
  Link,
}
