import React from 'react'
import { mapPreviousDetailToAnswer } from '../components/Types/functions'
import { AppContext } from '../context/AppContext'
import { capitalizeFirstLetter, denormalizeString } from './format'

export const withContext = (Component) => {
  return (props) => (
    <AppContext.Consumer>
      {(context) => <Component {...props} context={context} />}
    </AppContext.Consumer>
  )
}

export const exportSectionData = (sectionRow, data, deleteUnusedData) => {
  let sections = [...mapPreviousDetailToAnswer(data.sections || [])]

  sections.forEach((section) => {
    for (let i = 0; i < section.detail.length; i++) {
      const detail = section.detail[i]

      let title = `"${detail.question}"`
      let value = detail.value || '-'

      if (detail.type === 'placevisit' || detail.type === 'freevisit')
        value = detail?.value?.latitude
          ? `"${detail?.value?.latitude},${detail?.value?.longitude}"`
          : ''
      if (
        detail.type === 'uploadsingle' ||
        detail?.type === 'uploadsinglevalidation' ||
        detail.type === 'fileupload' ||
        detail?.type === 'uploadsinglegallery'
      )
        value = detail?.value?.url || ''
      if (detail.type === 'survey') value = detail?.value?.label || ''

      if (title !== null) {
        sectionRow[denormalizeString(capitalizeFirstLetter(title))] = value
      }

      if (detail.type === 'addressdetail') {
        if (detail.answer.length > 0) {
          const addressDetailAnswer = deleteUnusedData(
            detail.answer[0].address_detail,
            'addressDetail',
          )

          for (const [key, val] of Object.entries(addressDetailAnswer)) {
            let addressDetailVal = val
            if (key === 'urban' || key === 'full_address') {
              addressDetailVal = `"${val}"`
            }
            const title = denormalizeString(capitalizeFirstLetter(key))

            sectionRow[title] = addressDetailVal
          }
        } else {
          const addressDetail = {
            full_address: '',
            province: '',
            district: '',
            urban: '',
            suburban: '',
            postal_code: '',
          }
          for (const [key, val] of Object.entries(addressDetail)) {
            const title = denormalizeString(capitalizeFirstLetter(key))
            sectionRow[title] = '-'
          }
        }
      }
    }
  })
}

export const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

export const getPageAccess = (getPermission, currentPageName) => {
  const { default_role, permissions } = getPermission
  const getCurrPagePermission = permissions?.find(
    (item) => item.name === currentPageName,
  )
  return {
    view: default_role ? default_role : getCurrPagePermission?.actions?.view,
    approval: default_role
      ? default_role
      : getCurrPagePermission?.actions?.approval,
    create: default_role
      ? default_role
      : getCurrPagePermission?.actions?.create,
    delete: default_role
      ? default_role
      : getCurrPagePermission?.actions?.delete,
    update: default_role
      ? default_role
      : getCurrPagePermission?.actions?.update,
  }
}

const addOrRemoveSelectedData = (selectedData, item, skipDuplicate) => {
  const exist = selectedData.findIndex((data) => data?.id === item?.id)
  if (exist > -1) {
    if (skipDuplicate) return

    selectedData.splice(exist, 1)
  } else {
    selectedData.push(item)
  }
}

export const addSelectedData = (oldData, newData, skipDuplicate, setState) => {
  const newSelectedData = [...oldData]

  if (Array.isArray(newData)) {
    newData.forEach((item) => {
      addOrRemoveSelectedData(newSelectedData, item, skipDuplicate)
    })
  } else {
    addOrRemoveSelectedData(newSelectedData, newData, skipDuplicate)
  }

  setState(newSelectedData)
}
