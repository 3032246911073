import React from 'react'
import Spinner from './Spinner'
import classNames from 'classnames'

const ModalLoading = ({ open }) => {
  return (
    <div className={classNames('modal-loading', { open })}>
      <div className="overlay" />
      <div className="modal-loading-body">
        <Spinner name="pulse" size={30} margin="0 auto" />
      </div>
    </div>
  )
}

export default ModalLoading
