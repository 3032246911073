import React from 'react'
import { ButtonStyled } from '../../assets/components/components'
import { NEUTRAL_30, NEUTRAL_50, NEUTRAL_60 } from '../../assets/styles/styles'
import Spinner from '../Spinner/Spinner'

const Button = ({
  block,
  rounded,
  uppercase,
  color,
  radius,
  padding,
  children,
  width,
  margin,
  disabled,
  onClick,
  border,
  backgroundColor,
  textColor,
  loading,
  loadingComponent,
  loadingMargin,
  loadingWhite,
  disabledChangeColor = false,
  ...props
}) => {
  return (
    <ButtonStyled
      {...props}
      uppercase={uppercase}
      radius={radius}
      rounded={rounded}
      padding={padding}
      color={color}
      block={block}
      width={width}
      margin={margin}
      onClick={disabled || loading ? () => {} : onClick}
      border={border}
      backgroundColor={backgroundColor}
      textColor={loading || disabled ? `${NEUTRAL_60} !important` : textColor}
      disabledChangeColor={disabledChangeColor}
      style={
        loading || disabled
          ? { backgroundColor: NEUTRAL_30, borderColor: NEUTRAL_50 }
          : {}
      }
    >
      {loading
        ? loadingComponent || (
            <Spinner
              name="threebounce"
              size={26}
              white={
                loadingWhite ? loadingWhite : loading ? false : loadingWhite
              }
              disabled={loading}
              margin={loadingMargin || '-5px auto'}
            />
          )
        : children}
    </ButtonStyled>
  )
}

Button.defaultProps = {
  block: false,
  rounded: false,
  color: '',
  radius: '5px',
  className: '',
  uppercase: false,
  padding: '10px 15px',
  width: 'auto',
  margin: '0',
  disabled: false,
}

export default Button
