import constants from '../../utils/constants'
export const GET_STEP_PROGRESS = 'autoAllocation/GET_STEP_PROGRESS'
export const GET_IMPORTED_FILE = 'autoAllocation/GET_IMPORTED_FILE'

export const setStepProgress =
  (step, status, tempAssignmentId) => async (dispatch) => {
    const getLocalStorageData = localStorage.getItem(
      constants.localStorage.autoAllocation.STEPS_PROGRESS,
    )
      ? JSON.parse(
          localStorage.getItem(
            constants.localStorage.autoAllocation.STEPS_PROGRESS,
          ),
        )
      : {}
    let stepProgressData = {
      ...getLocalStorageData,
      id: tempAssignmentId,
      data: {
        ...getLocalStorageData?.data,
        [step]: status,
      },
    }
    localStorage.setItem(
      constants.localStorage.autoAllocation.STEPS_PROGRESS,
      JSON.stringify(stepProgressData),
    )
    dispatch(getStepProgress())
  }

export const getStepProgress = () => async (dispatch) => {
  const stepProgressData = localStorage.getItem(
    constants.localStorage.autoAllocation.STEPS_PROGRESS,
  )
  const result = JSON.parse(stepProgressData)

  dispatch({ type: GET_STEP_PROGRESS, data: result })
}

export const checkStepProgress = (allocationType) => async (dispatch) => {
  const stepProgressData = JSON.parse(
    localStorage.getItem(constants.localStorage.autoAllocation.STEPS_PROGRESS),
  )

  const location = window.location.href
  if (location.includes('/step-1')) {
    dispatch(
      clearLocalStorage(
        constants.localStorage.autoAllocation.STORE_IMPORTED_FILE,
      ),
    )
    stepProgressData?.data?.step2 && dispatch(setStepProgress('step2', ''))
    stepProgressData?.data?.step3 && dispatch(setStepProgress('step3', ''))
  } else if (location.includes('/step-2')) {
    if (stepProgressData?.data?.step1 === 'incomplete') {
      window.location.href =
        allocationType === 'new'
          ? constants.routes.autoAllocation.AUTO_ALLOCATION_NEW_STEP_1
          : constants.routes.autoAllocation.AUTO_ALLOCATION_EXISTING_STEP_1
      return
    }

    stepProgressData?.data?.step3 && dispatch(setStepProgress('step3', ''))
    dispatch(getImportedFile())
  }

  dispatch(getStepProgress())
}

export const setImportedFile =
  (type, filename, tempAssignmentId) => async (dispatch, state) => {
    const getLocalStorageData = localStorage.getItem(
      constants.localStorage.autoAllocation.STORE_IMPORTED_FILE,
    )
      ? JSON.parse(
          localStorage.getItem(
            constants.localStorage.autoAllocation.STORE_IMPORTED_FILE,
          ),
        )
      : {}

    let importedFile = {
      ...getLocalStorageData,
      id: tempAssignmentId,
      data: {
        ...getLocalStorageData?.data,
        [type]: filename,
      },
    }
    localStorage.setItem(
      constants.localStorage.autoAllocation.STORE_IMPORTED_FILE,
      JSON.stringify(importedFile),
    )
    dispatch(getImportedFile())
  }

export const getImportedFile = () => async (dispatch) => {
  const importFileData = localStorage.getItem(
    constants.localStorage.autoAllocation.STORE_IMPORTED_FILE,
  )
  const result = JSON.parse(importFileData)

  dispatch({ type: GET_IMPORTED_FILE, data: result })
}

export const checkImportedFile = (tempAssignmentId) => async (dispatch) => {
  const getLocalStorageData = (await localStorage.getItem(
    constants.localStorage.autoAllocation.STORE_IMPORTED_FILE,
  ))
    ? JSON.parse(
        localStorage.getItem(
          constants.localStorage.autoAllocation.STORE_IMPORTED_FILE,
        ),
      )
    : {}

  if (getLocalStorageData?.id !== tempAssignmentId) {
    dispatch(
      clearLocalStorage(
        constants.localStorage.autoAllocation.STORE_IMPORTED_FILE,
      ),
    )
  }
}

export const clearLocalStorage = (type) => async (dispatch) => {
  if (type === constants.localStorage.autoAllocation.STEPS_PROGRESS) {
    localStorage.removeItem(
      constants.localStorage.autoAllocation.STEPS_PROGRESS,
    )
  } else if (
    type === constants.localStorage.autoAllocation.STORE_IMPORTED_FILE
  ) {
    localStorage.removeItem(
      constants.localStorage.autoAllocation.STORE_IMPORTED_FILE,
    )
  } else if (type === 'all') {
    localStorage.clear()
  }
}
