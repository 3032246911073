import { applyMiddleware, compose, createStore } from 'redux'
import thunk from 'redux-thunk'
import authMiddleware from '../middleware/auth'
import rootReducer from './reducers'

const initialState = {}
const enhancers = []
const middleware = [thunk, authMiddleware]

if (process.env.MODE_ENV === 'staging') {
  const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__

  if (typeof devToolsExtension === 'function') {
    enhancers.push(devToolsExtension())
  }
}

const composedEnhancers = compose(applyMiddleware(...middleware), ...enhancers)

const store = createStore(rootReducer, initialState, composedEnhancers)

export default store
