import { GET_STEP_PROGRESS, GET_IMPORTED_FILE } from '../actions/autoAllocation'

const initialState = {
  stepsProgress: {},
  importedFile: {},
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_STEP_PROGRESS:
      return {
        ...state,
        stepsProgress: action.data,
      }
    case GET_IMPORTED_FILE:
      return {
        ...state,
        importedFile: action.data,
      }
    default:
      return state
  }
}
