import axios from 'axios'

export default async () => {
  axios.interceptors.request.use(
    (config) => {
      let login_data = localStorage.getItem('login_data')

      if (login_data) login_data = JSON.parse(login_data)
      const token = login_data?.token || ''

      if (token !== undefined && token !== null && token !== '') {
        config.headers['x-token'] = token
      }
      config.headers['Content-Type'] = 'application/json'
      return config
    },
    (err) => Promise.reject(err),
  )

  axios.interceptors.response.use(
    (resp) => resp,
    (error) => {
      if (error.response) {
        if (error.response.status === 501 || error.response.status === 502) {
          return Promise.reject(error)
        }

        if (
          error.response.config.method === 'post' ||
          error.response.config.method === 'put'
        ) {
          if (error.response.status === 401) {
            return Promise.reject(error)
          }
          if (
            error.response.status.toString().charAt(0) === '4' ||
            error.response.status.toString().charAt(0) === '5'
          ) {
            return Promise.reject(error.response.data)
          }
        }
      }
      return Promise.reject(error)
    },
  )
}
