import 'firebase/analytics'
import firebase from 'firebase/app'
import { firebaseConfig } from '../../utils/key'

export const firebaseInit = async () => {
  let initializedFirebaseApp = ''

  if (firebase.apps?.length > 0) {
    initializedFirebaseApp = firebase.app() // if already initialized, use that one
  } else {
    initializedFirebaseApp = firebase.initializeApp(firebaseConfig)
  }

  firebase.analytics(initializedFirebaseApp)
}
