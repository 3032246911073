import {
  SET_AUTHENTICATED,
  SET_USER_DATA,
  SET_WORKSPACE_DATA,
} from '../actions/auth'

const initialState = {
  user: {},
  permissions: {},
  token: '',
  authenticated: false,
  workspace: {},
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_AUTHENTICATED:
      return { ...state, authenticated: action.isAuthenticated }
    case SET_USER_DATA:
      return {
        ...state,
        token: action.token,
        user: action.user,
        permissions: action.permissions,
      }
    case SET_WORKSPACE_DATA:
      return {
        ...state,
        workspace: action.workspace,
      }
    default:
      return state
  }
}
