export const SIDEBAR_TOGGLE = 'dashboard/SIDEBAR_TOGGLE'
export const SIDEBAR_SHOW = 'dashboard/SIDEBAR_SHOW'
export const SIDEBAR_HIDE = 'dashboard/SIDEBAR_HIDE'
export const SET_BREADCRUMB = 'dashboard/SET_BREADRUMB'
export const SET_ASIDE = 'dashboard/SET_ASIDE'

export const toggleSidebar = (sidebarShow) => async (dispatch, getState) => {
  await dispatch({
    type: SIDEBAR_TOGGLE,
    sidebarShow,
  })
}

export const toggleAside = (asideState) => async (dispatch) => {
  await dispatch({
    type: SET_ASIDE,
    asideState,
  })
}

export const setBreadCrumb = (breadcrumb) => async (dispatch) => {
  await dispatch({
    type: SET_BREADCRUMB,
    breadcrumb,
  })
}
