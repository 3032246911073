import {
  setAuthenticated,
  setUserData,
  setWorkspaceData,
} from '../redux/actions/auth'
import constants from '../utils/constants'

export const LOAD_LOGIN_DATA = 'auth/LOAD_LOGIN_DATA'
export const VERIFY_DATA = 'auth/VERIFY_DATA'
export const LOAD_WORKSPACE_DATA = 'auth/LOAD_WORKSPACE_DATA'

const authMiddleware = (store) => (next) => async (action) => {
  if (action.type === LOAD_LOGIN_DATA) {
    const data = localStorage.getItem(constants.localStorage.auth.AUTH_LOGIN)

    const parsed_data = JSON.parse(data)

    if (parsed_data) {
      store.dispatch(setAuthenticated(true))
      store.dispatch(
        setUserData(
          parsed_data.token,
          parsed_data.user,
          parsed_data.permissions,
        ),
      )
    }
  } else if (action.type === LOAD_WORKSPACE_DATA) {
    const data = localStorage.getItem(
      constants.localStorage.auth.WORKSPACE_AUTH_LOGIN,
    )

    const parsed_data = JSON.parse(data)

    if (parsed_data) {
      store.dispatch(setWorkspaceData(parsed_data))
    }
  } else {
    next(action)
  }
}

export default authMiddleware
