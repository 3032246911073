import {
  ApolloClient,
  ApolloProvider,
  createHttpLink,
  from,
  HttpLink,
  InMemoryCache,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import constants from '../utils/constants'

const getUrlFromAsyncStorage = async () => {
  const res = localStorage.getItem(
    constants.localStorage.auth.WORKSPACE_AUTH_LOGIN,
  )

  let api = process.env.REACT_APP_WORKSPACE_API_GRAPHQL
  if (res) {
    api = JSON.parse(res).api
  }

  return api
}

const customFetch = async (uri, options) => {
  const api = await getUrlFromAsyncStorage()

  return fetch(api + uri, options)
}

const httpLink = new HttpLink({
  credentials: 'same-origin',
  fetch: customFetch,
})

const authMiddleware = setContext((operation) => {
  let login_data = localStorage.getItem('login_data')
  if (login_data) login_data = JSON.parse(login_data)
  const token = login_data?.token || ''

  if (token) {
    return {
      headers: {
        'x-token': token,
      },
    }
  }
})

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach((error) => {
      const { message, extensions } = error
      if (extensions.code === 'FORBIDDEN') {
        if (message === 'Please relogin after change password') {
          localStorage.removeItem('login_data')
          window.location.href = '/login'
        } else {
          localStorage.clear()
          window.location.href = '/workspace'
        }
      }
    })

  if (networkError) {
  }
})

// if multiple endpoint
// ------------------------------------------------------------------------
// const httpLinks = ApolloLink.split(
//   (operation) => operation.getContext().clientName === 'reporting',
//   reportingEndpoint, //if above
//   mainEndpoint,
// )
// ------------------------------------------------------------------------

export const client = new ApolloClient({
  link: from([authMiddleware, errorLink, httpLink]),
  cache: new InMemoryCache(),
})
