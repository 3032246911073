export const IMAGE_FILE_FORMAT = ['image/png', 'image/jpeg', 'image/jpeg']
export const ALL_FILE_FORMAT = [
  ...IMAGE_FILE_FORMAT,
  'text/csv',
  '.doc',
  '.docx',
  'application/pdf',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/msword',
]

export default {
  image: IMAGE_FILE_FORMAT,
  all_file: ALL_FILE_FORMAT,
}
