export const STEPS_PROGRESS = 'step_progress'
export const STORE_IMPORTED_FILE = 'imported_file'
export const AUTH_LOGIN = 'login_data'
export const WORKSPACE_AUTH_LOGIN = 'workspace'
export const FORGOT_PASSWORD_VERFICATION_TIMER = 'forgot_password_verif_timer'

export default {
  autoAllocation: {
    STEPS_PROGRESS,
    STORE_IMPORTED_FILE,
  },
  auth: {
    AUTH_LOGIN,
    WORKSPACE_AUTH_LOGIN,
  },
  forgotPassword: {
    FORGOT_PASSWORD_VERFICATION_TIMER,
  },
}
