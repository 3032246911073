import { combineReducers } from 'redux'
import dashboard from './dashboard'
import autoAllocation from './autoAllocation'
import auth from './auth'
import app from './app'

const rootReducer = combineReducers({
  dashboard,
  autoAllocation,
  auth,
  app,
})

export default rootReducer
