import { SET_ROUTE_NAME } from '../actions/app'

const initialState = {
  routeName: '',
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_ROUTE_NAME:
      return { ...state, routeName: action.route }

    default:
      return state
  }
}
