import { gql } from '@apollo/client'

export const GET_LOGFILE_NOTIFICATION = gql`
  query logFilesNotification(
    $status: String
    $type: String
    $show_read: Boolean
  ) {
    logFilesNotification(status: $status, type: $type, show_read: $show_read) {
      id
      name
      failed_file_name
      status
      is_read
      status
      type
      menu_type
      updated_at
      created_at
      success_row_amount
      total_uploaded
    }
  }
`

export const EXPORT_LOGFILE = gql`
  mutation logfiledownloadfiles($log_file_id: String!) {
    logfiledownloadfiles(log_file_id: $log_file_id) {
      id
      name
      url
      failed_url
      failed_file_name
    }
  }
`
export const UPDATE_RETRY_LOGFILE = gql`
  mutation logFileRetryExport($log_file_id: String!) {
    logFileRetryExport(log_file_id: $log_file_id)
  }
`

export const GET_UPDATED_USER_DATA = gql`
  query me {
    me {
      id
      name
      email
      phone_number
      role_permissions {
        permissions {
          page {
            id
            name
            group
          }
          name
          actions {
            view
            update
            create
            delete
            approval
          }
        }
      }
    }
  }
`

export const UPDATE_NOTIFICATION_MARK_AS_READ = gql`
  mutation logfileMarkAsRead($log_file_id: String) {
    logfileMarkAsRead(log_file_id: $log_file_id)
  }
`

export const USER_FORCE_LOGOUT = gql`
  query userForceLogout($user_id: String) {
    userForceLogout(user_id: $user_id)
  }
`
