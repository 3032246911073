// color
export const white = '#fff'
export const black = '#4a4a4a'
export const border = '#e4e4e4'
export const blue = '#3583ee'
export const blue10 = '#e1eeff'
export const blue20 = '#f6faff'
export const bluelight = '#DCE7F7'
export const brightred = '#ff3333'
export const red = '#F86D6B'
export const redsoft = '#e76969'
export const redbg = 'rgba(255,79,79,0.06)'
export const grey = '#cdcdcd'
export const darkGrey = '#9e9e9e'
export const green = '#00AF85'
export const lightGreen = '#F4FFFD'
export const darkBlue1 = '#303265'
////////////////////////////////////////////////////

// colors
export const PRIMARY_0 = '#F5FAFF'
export const PRIMARY_10 = '#E1EEFF'
export const PRIMARY_20 = '#BCD6F9'
export const PRIMARY_30 = '#9AC1F6'
export const PRIMARY_40 = '#78ACF4'
export const PRIMARY_50 = '#5798F1'
export const PRIMARY_60 = '#3583EE'
export const PRIMARY_70 = '#2C6DC6'
export const PRIMARY_80 = '#23579F'
export const PRIMARY_90 = '#1A4177'
export const PRIMARY_100 = '#122C4F'

export const SECONDARY_0 = '#f8fffd'
export const SECONDARY_10 = '#e2fff8'
export const SECONDARY_30 = '#b6ffee'
export const SECONDARY_40 = '#9effe8'
export const SECONDARY_60 = '#6effdd'
export const SECONDARY_70 = '#5cd4b8'
export const SECONDARY_90 = '#25554a'
export const SECONDARY_100 = '#16332c'

export const NEUTRAL_10 = '#FFFFFF'
export const NEUTRAL_20 = '#F9FAFB'
export const NEUTRAL_30 = '#EDEDED'
export const NEUTRAL_40 = '#E0E0E0'
export const NEUTRAL_50 = '#C2C2C2'
export const NEUTRAL_60 = '#9E9E9E'
export const NEUTRAL_70 = '#757575'
export const NEUTRAL_80 = '#616161'
export const NEUTRAL_90 = '#404040'
export const NEUTRAL_100 = '#0A0A0A'

export const RED_0 = '#FFF8F8'
export const RED_10 = '#FFECEC'
export const RED_20 = '#ffc2c2'
export const RED_50 = '#F24242'
export const RED_70 = '#AE1E1E'
export const RED_80 = '#8C1111'
export const RED_100 = '#480202'

export const GREEN_0 = '#F4FFFD'
export const GREEN_10 = '#CCEFE7'
export const GREEN_20 = '#AAE4D6'
export const GREEN_40 = '#55CAAE'
export const GREEN_50 = '#2ABC99'
export const GREEN_60 = '#00AF85'
export const GREEN_70 = '#00926F'
export const GREEN_90 = '#005742'
export const GREEN_100 = '#003A2C'

export const YELLOW_0 = '#FFFEF8'
export const YELLOW_10 = '#FFFCEB'
export const YELLOW_20 = '#FFF6BD'
export const YELLOW_30 = '#FFEF90'
export const YELLOW_50 = '#FFE336'
export const YELLOW_60 = '#DDC323'
export const YELLOW_70 = '#BBA414'
export const YELLOW_90 = '#776600'

export const PURPLE_10 = '#F9F5FF'
export const PURPLE_20 = '#F4EBFF'
export const PURPLE_30 = '#E9D7FE'
export const PURPLE_40 = '#D6BBFB'
export const PURPLE_50 = '#B692F6'
export const PURPLE_60 = '#9E77ED'
export const PURPLE_70 = '#7F56D9'
export const PURPLE_80 = '#6941C6'
export const PURPLE_90 = '#53389E'
export const PURPLE_100 = '#42307D'

export const RED_VOLCANO_10 = '#FFF2E8'
export const RED_VOLCANO_30 = '#FFBB96'
export const RED_VOLCANO_40 = '#FF7C4F'
export const RED_VOLCANO_60 = '#FA541C'

// font weight
export const FONT_WEIGHT_REGULAR = '400'
export const FONT_WEIGHT_MEDIUM = '500'
export const FONT_WEIGHT_SEMI_BOLD = '600'
export const FONT_WEIGHT_BOLD = '700'

// font size
export const TEXT_EXTRA_SMALL = '0.75rem' //12px
export const TEXT_SMALL = '.875rem' // 14px
export const TEXT_MEDIUM = '1rem' // 16px
export const TEXT_LARGE = '1.125rem' //18px

export const TITLE_SMALL = '1.265rem' //20px
export const TITLE_MEDIUM = '1.5rem' //24px
export const TITLE_LARGE = '1.8rem' //28px

export const DISPLAY_SMALL = '2rem' //32px
export const DISPLAY_MEDIUM = '3rem' //48px
export const DISPLAY_LARGE = '4rem' //64px
